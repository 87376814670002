export default {
	common: {
		korean: '한국어',
		english: 'English',
		thai: 'ภาษาไทย'
	},

	word: {
		navSubWallet: '지갑',
		navCompany: '회사',
		navTKWorld: 'TKWorld',
		navCommunity: '커뮤니티',
		navSubHome: '홈',
		navSubProfile: '프로필',
		navSubCompany: '회사',
		navSubTeam: '팀',
		navSubHistory: '역사',
		navSubRoadmap: '로드맵',
		navSubStore: '스토어',
		navSubTKMedia: 'TK 미디어',
		navSubOnlineMall: '온라인 몰',
		navSubTour: '투어',
		navSubTKStory: 'TK 스토리',
		navSubBlog: '블로그',
		navSubEvent: '이벤트',
		navSubManual: '메뉴얼',
		siteMapSns: 'SNS',
		siteMapExplore: 'Explore',
		siteMapHome: '홈',
		siteMapProfile: '프로필',
		siteMapCompany: '회사',
		siteMapTeam: '팀',
		siteMapHistory: '역사',
		siteMapRoadMap: '로드맵',
		siteMapParticipate: '참여',
		siteMapBlog: '블로그',
		siteMapEvent: '이벤트',
		siteMapManual: '메뉴얼',
		siteMapAbout: '정보',
		siteMapPrivacy: '개인정보 보호정책',
		siteMapContact: '문의하기',
		siteMapEmail: "tkcpay0{'@'}gmail.com",
		siteMapTel: '(82+) 1577 - 1352',
		siteMapAddress: '경기도 하남시\n 조정대로 150',
		btnWhitePaper: '백서',
		sectors_00: 'etc',
		sectors_01: 'the lodging business',
		sectors_02: 'retail business',
		sectors_03: 'wholesale business',
		sectors_04: 'the skincare industry',
		homepage: 'Homepage',
		tel: 'Tel',
		useTime: 'business hours'
	},

	message: {
		navSubHome: 'TKW 홈으로 이동',
		navSubProfile: '회사소개',
		navSubCompany: 'TKW에 대해 자세히 알아보기',
		navSubTeam: '팀에 대해 자세히 알아보기',
		navSubHistory: '역사에 대해 자세히 알아보기',
		navSubRoadmap: '로드맵에 대해 자세히 알아보기',
		navSubStore: 'TKW 스토어로 이동',
		navSubTKMedia: 'TK 미디어에 대해 자세히 알아보기',
		navSubOnlineMall: '온라인몰로 이동',
		navSubTour: '투어에 대해 자세히 알아보기',
		navSubTKStory: 'TK 스토리에 대해 자세히 알아보기',
		navSubBlog: '최신 업데이트를 보려면 블로그 홈으로 이동하세요.',
		navSubEvent: '이벤트 페이지를 확인하세요',
		navSubManual: '메뉴얼 페이지를 확인하세요'
	},
	home: {
		topRewardsTitle: 'What rewards are there?',
		topRewardsDescription:
			'A system in which product purchases\nand usage amounts are rewarded,\nand consumers receive the profits\nback as soon as they use the product\nand build a system that can be used\n immediately.',
		topOnlineShoppingTitle: 'Online Shopping',
		topOnlineShoppingDescription:
			'Building an online shopping mall\nlinked to TV home shopping\nSNS consumer marketing using\nbroadcast content\nEstablishment of TK Pay platform\n for members',
		topTKTravelTitle: 'TK travel products have\nsomething special.',
		topTKTravelDescription:
			'TK Tour is the first in the travel\nindustry to provide a 30%\nrefund on all travel expenses\nwhen paying with TK PAY',
		topTKMediaTitle: 'What is TK Media?',
		topTKMediaDescription:
			"TK World's media business is a hot\ntopic locally due to the popularity\n and awareness of TV home\n shopping through public\n broadcasting programs and satellite\n broadcasting.",
		contents01_youtube01_title: 'TKPAY 해외 아마추어 골프 대회 SBS Golf-01',
		contents01_youtube02_title: 'TKPAY 해외 아마추어 골프 대회 SBS Golf-02',
		contents01_sub01_title: "The Client's View\nOf The Token Economy",
		contents01_sub01_subtitle: 'Why does this business need a token?',
		contents01_sub01_description:
			'A TRC20-based blockchain cryptocurrency that\nhas the same value as traditional money, such as\ncash, checks, and credit cards, and is an electronic\nsecurity that can be transferred over a network.\nThe emergence of TK Pay is inevitable in the\ninformation society, as it is a blockchain payment\nsystem that makes it easy to make payments\nanytime, anywhere, instead of using\nunmanageable cash.',
		contents02_title: 'King of sales',
		contents02_description: 'Thai National Broadcasting (CH5)',
		contents03_sub01_title: 'Introducing Platforms',
		contents03_sub01a_title: 'Platform and item features',
		contents03_sub01a_description:
			'TrendKorea World is a distribution platform company\nthat combines media and IT technology. Based on 20\nyears of broadcasting and distribution business in\nThailand, a hub country in Southeast Asia, TK World is\nexpanding into the first global platform business by\ncombining Korean IT technology and creating a new\nconcept of distribution culture.',
		contents03_sub01b_title: 'Background of platform\nintroduction',
		contents03_sub01b_description:
			'Blockchain and smart contracts need to revolutionize the\n outdated supply chain.\nReward consumers by reducing excessive distribution\n and marketing costs.\nBuild a blockchain smart contract service that directly\nconnects manufacturers and consumers Provide web/\napp shopping malls for consumers and business\ninterfaces for producers/manufacturers\nUse TK as a means of purchasing\nand rewarding products in consumer loyalty programs',
		contents03_sub01c_title: 'Differentiate From Existing\nPlatforms/items:',
		contents03_sub01c_description:
			'1. With a smartphone, you can trade freely regardless of\nborders and time barriers\n2. Quickly process payments via QR scans\n3. prevent forgery, duplication, denial, etc. of transaction\ninformation by proving that the physical object does not\nexist and its possession and transfer are proven by digital\nrecords.It can prevent forgery\n4. Users can earn 30% of the maximum amount spent on\nTK Pay over 500 times in accordance with the reward\nsystem.',
		contents03_sub02a_title: 'What’s New at TKW Blog',
		contents03_sub02a_description: 'News and news from the TK platform',
		contents03_sub02b_title: 'New Event From TKW',
		contents03_sub02b_description: "Let's check out TKW special event"
	},
	profile: {
		contents01_text: 'KBS : [지구촌 현장] “한국 알아야 ‘퀴즈왕’” (kbs.co.kr)'
	},
	company: {
		topTitle: 'What our company does',
		topDescription: 'We are a company that provides\nservices with a customer-centric\napproach in the field of shopping\nand distribution',
		contents01_title: 'Company',
		contents01_subtitle: 'Trend Korea World is a distribution platform\ncompany that combines media and IT technology.',
		contents01_description:
			"TK World has over 20 years of broadcasting business in\nThailand, a hub country in Southeast Asia.\nBased on the distribution business and incorporating Korea's\n IT technology,\nWe are expanding into a platform business and creating a\nnew concept of distribution culture.",
		contents01_box_title: 'Overview',
		contents01_box_sub01_title: 'Company Name',
		contents01_box_sub01_description: '㈜ 티케이월드 (Korea Corporation) / TKWORLD Co.,Ltd. (Thailand Corporation)',
		contents01_box_sub02_title: 'Date of establishment',
		contents01_box_sub02_description: 'Established in March 2022 (Thailand corporation 2007~)',
		contents01_box_sub03_title: 'Representative',
		contents01_box_sub03_description: 'Do-Hyung Kim (Chairman) / Corporate Representative: Sang-Ho Kim(CEO)',
		contents01_box_sub04_title: 'Corporate location',
		contents01_box_sub04_description:
			'Korea :150, Jojeong-daero, Hanam-si, Gyeonggi-do, Republic of Korea (Orange Zone 1st floor)\nThailand : 75/40 Richmond office Building Bangkok (10110)',
		contents02_title: 'Bussiness Plan',
		contents02_subtitle: 'TK Pay 2020',
		contents02_box_sub01_title: '1. High-Technology',
		contents02_box_sub01_description: 'A new payment system that\nbenefits consumers with Korea’s\nexcellent blockchain technology',
		contents02_box_sub02_title: '2. Brod Casting',
		contents02_box_sub02_description: 'A new payment system that\nbenefits consumers with Korea’s\nexcellent blockchain technology',
		contents02_box_sub03_title: '3. High-Technology',
		contents02_box_sub03_description: 'A new payment system that\nbenefits consumers with Korea’s\nexcellent blockchain technology',
		contents02_box_sub01_bottom_title: 'Curiosity',
		contents02_box_sub01_bottom_description: 'Marketing-- Unexpected',
		contents02_box_sub02_bottom_title: 'Catch Sight',
		contents02_box_sub02_bottom_description: 'Touching - Unexpected',
		contents02_box_sub03_bottom_title: 'Bounce',
		contents02_box_sub03_bottom_description: 'Experiencing- Unexpected',
		contents03_title: 'Bussiness Plan Content',
		contents03_subtitle: 'Systematic + Ecosystem',
		contents03_box_sub01_title: 'TV Home Shopping',
		contents03_box_sub01_ul_01: 'Reward 50% to 100% of sales price for all broadcast products',
		contents03_box_sub01_ul_02: 'Consumer marketing, including Korea’s outstanding product quality and competitive prices',
		contents03_box_sub01_ul_03: 'Applying TKPay payment system to locally competitive products',
		contents03_box_sub01_ul_04: 'TKPay offline stores and online strategic promotional marketing',
		contents03_box_sub02_title: 'Online',
		contents03_box_sub02_ul_01: '50%~100% reward on all online products',
		contents03_box_sub02_ul_02: 'Establishment of an online shopping mall linked to TV home shopping',
		contents03_box_sub02_ul_03: 'SNS consumer marketing using broadcast content',
		contents03_box_sub02_ul_04: 'Establishment of TKPay platform for distribution members',
		contents03_box_sub03_title: 'Offline',
		contents03_box_sub03_ul_01: 'Store: Using storytelling content linked to TV home shopping',
		contents03_box_sub03_ul_02: 'Securing TKPay members and expanding offline stores',
		contents03_box_sub03_ul_03: 'MLM operator: TKPay 50% and 00% through reward system',
		contents03_box_sub03_ul_04: 'Establishing competitive system direct sales (*Multi Level Marketing)',
		contents04_title: 'Additionel Bussiness',
		contents04_box_sub01_title: 'Overseas export sector',
		contents04_box_sub01_subtitle_01: 'Companies wishing to expand overseas',
		contents04_box_sub01_description_01:
			'Korean promising small and medium-sized business product launch (broadcasting and launching costs)',
		contents04_box_sub01_subtitle_02: 'Cooperation with Small and Medium Business Administration',
		contents04_box_sub01_description_02: 'Proposal of product advertising broadcast\nprofitability model',
		contents04_box_right_sub01_title: 'Special business connection',
		contents04_box_right_sub01_description: 'Government and\npublic enterprises',
		contents04_box_sub02_title: 'Travel business division',
		contents04_box_sub02_subtitle_01: 'Inbound',
		contents04_box_sub02_description_01:
			'Linkage with travel agency tour products (affiliate promotion) Medical tourism (plastic surgery, dermatology, etc.)',
		contents04_box_sub02_subtitle_02: 'Outbound',
		contents04_box_sub02_description_02:
			'Thailand affiliated store tour\n(Hotel, golf course, restaurant, souvenir shop,\netc.) Total Entertainment',
		contents04_box_right_sub02_title: 'TKC',
		contents04_box_right_sub02_description: 'TK Pay\n30% Reward',
		contents05_title: 'Token Information',
		contents05_sub01_title: 'Token Name / Token Symbol',
		contents05_sub01_description: 'TKCOIN / TKC',
		contents05_sub02_title: 'Blockchain Base',
		contents05_sub02_description: 'TRC-20',
		contents05_sub03_title: 'Token Supply',
		contents05_sub03_description: '2 Billion',
		contents05_sub04_title: 'Token Distribution',
		contents05_sub04_description: 'Team 20%, Sale 10%, Marketing 20%, Partner20%, Event 10%, Community 20%',
		contents05_paper_btn_down: 'White Paper\nDownload',
		contents05_graph_text_01: 'Team 20%',
		contents05_graph_text_02: 'Marketing 20%',
		contents05_graph_text_03: 'Event 10%',
		contents05_graph_text_04: 'Sale 10%',
		contents05_graph_text_05: 'Community 20%',
		contents05_graph_text_06: 'Partner 20%'
	},
	team: {
		topTitle: 'What about our members?',
		topDescription: 'We strive to constantly learn and develop.\nOur efforts and passion will lead us to a\nbetter place',
		contents01_title: 'Team',
		contents01_box_sub01_title: 'Chairman',
		contents01_box_sub01_description: 'Do Hyung Kim 김 도 형',
		contents01_box_sub01_ul_01: '1997 - Public relations team',
		contents01_box_sub01_ul_02: '2005 - 2006 Cultural business team pd',
		contents01_box_sub01_ul_03: '2007 - 2012 CEO of DHB Media',
		contents01_box_sub01_ul_04: '2012 - Changed Tk media Bali corporation',
		contents01_box_sub02_title: 'CEO',
		contents01_box_sub02_description: 'Kim Sang Ho 김 상 호',
		contents01_box_sub02_ul_01: '1991 - Department of Management, Soongsil University',
		contents01_box_sub02_ul_02: 'Andong University Graduate School of Business (Management, Consumer Marketing)',
		contents01_box_sub02_ul_03: '1991 ~ 1994 Lotte Flavors General Affairs Department',
		contents01_box_sub02_ul_04: '1995 ~ 1997 Geosung Trading',
		contents01_box_sub02_ul_05: '1998 ~ 2003 KYC System Operation',
		contents01_box_sub02_ul_06: '2004 ~ 2010 Korea Natural Product Development Co.',
		contents01_box_sub02_ul_07: '2011 ~ 2016 Dongkuk Life Health',
		contents01_box_sub02_ul_08: '2016 ~ 2019 Winner Life Co.',
		contents01_box_sub02_ul_09: '2020 ~ CEO of TK World',
		contents01_box_sub03_title: 'CEO',
		contents01_box_sub03_description: 'Bong Jun Chaun 천 봉 준',
		contents01_box_sub03_ul_01: 'Chunsim Corporation',
		contents03_box_description_01: 'Expansion of profitable business using broadcasting (local satellite IV home shopping)',
		contents03_box_description_02:
			'Program planning and production to attract franchisees (story telling, pre-production to highlight franchise strengths)',
		contents03_box_description_03: 'Expansion of profitable business using broadcasting (local satellite IV home shopping)',
		contents04_title: 'Gallery'
	},
	history: {
		topTitle: 'The Path We Walked',
		topDescription:
			'Our journey is growing based on\npassion and trust. We pride ourselves\non always bringing vitality and joy,\nand we will move towards greater\nsuccess in our journey together.',
		contents01_title: 'History',
		contents01_2007_ul_01: 'Production and broadcast of King of saleson Thai National Broadcasting (CH5)',
		contents01_2007_ul_02: "First broadcast of Hallyu program housewife quiz show 'Miztime'",
		contents01_2007_ul_03: 'Broadcast Hallyu program Korean cultural experience "K-Variety',
		contents01_2007_ul_04:
			"Premiere of MBC drama 'Dogs, Wolves and Time' co-produced with Thailand\n(in cooperation with the Tourism Authority of Thailand and the Korean Embassy)",
		contents01_2007_ul_05: 'Held Asia-Pacific Supermodel Competition (Thailand)',
		contents01_2007_ul_06: "Preparation for Thailand's preliminary and final rounds in Korea",
		contents01_2007_ul_07: 'Live broadcast of Asia-Pacific Supermodel Contest Thailand (120 minutes)',
		contents01_2007_ul_08: 'Broadcast on TITV (Ch11) in Thailand',
		contents01_2007_ul_09:
			'Participated in <2007 Asia-Pacific Supermodel Competition> International Competition(4 countries: Korea, China, Japan, Thailand) Host broadcasters: SBS, Korea',
		contents01_2008_ul_01: 'SBS <Animal Farm> KBS <VJ Commando> Localproduction in Thailand',
		contents01_2008_ul_02: 'MBC <Special> <W> Local production in Thailand',
		contents01_2008_ul_03: 'Broadcasting partnership with local satellite broadcaster <H+> in Thailand',
		contents01_2008_ul_04: 'Thai TV home shopping program <Sanuksanuk TK>',
		contents01_2008_ul_05: 'premiered Broadcasting TV home shopping specialized in Hallyu products',
		contents01_2009_ul_01:
			'Planning and production of Thai local broadcasting programs related to. Started broadcasting (Thailand local) Retain local inbound tourists from Thailand',
		contents01_2011_ul_01: 'Change of corporate entity <TK Media',
		contents01_2011_ul_02: 'Launched Thai satellite broadcast <Trend Korea>.',
		contents01_2012_ul_01: 'Established in Thailand ',
		contents01_2012_ul_02: 'Acquired MLM license in Thailand ',
		contents01_2012_ul_03: 'Established own distribution market in Thailand ',
		contents01_2012_ul_04: 'Thai TV home shopping , connectionEstablished own distribution market in Thailand',
		contents01_2013_ul_01:
			'Preparation for establishment of <TK Cooperative>, authorization of <TK Cooperative> (Seoul Metropolitan Government) Awarded the first',
		contents01_2013_ul_02: 'Luxury Brand Grand Prize in the cooperative sector by The Korea Times Won the first',
		contents01_2013_ul_03: 'Innovative Company award in the cooperative sector organized by Sports Seoul',
		contents01_2014_ul_01:
			'Established Gyeongnam Changwon Branch (Cooperative) Excellent SME product sales outlet Opened offline stores in Gimhae, Gyeongnam, Busan, and Masan (7 locations)',
		contents01_2017_ul_01:
			'Cooperated with the Small and Medium Business Administration, exported to Thailand Launched Thai TV home shopping',
		contents01_2018_ul_01:
			'Established TK World Thailand local subsidiary Established TKC payment system Maintained TV home shopping and franchisees Local direct sales for offline flow Established local SNS shopping mall',
		contents01_2019_ul_01: 'Implementation of TK PAY payment system for the first time in Thai TV home shopping',
		contents01_2019_ul_02: '“50% reward points paid when paying with TK PAY”',
		contents01_2019_ul_03: 'Establishment of TK PAY offline affiliated store in Thailand',
		contents01_2019_ul_04: 'Thailand “Holly’s Coffee” Korean restaurant “Jangwon” “Jidubang Mart” etc.',
		contents01_2020_21_ul_01: 'Corona situation',
		contents01_2020_21_ul_02: 'Establishment of TK PAY exclusive shopping mall WWW.TKWKOREA.COM',
		contents01_2020_21_ul_03: 'Korea TK partner membership shopping mall operation',
		contents01_2022_ul_01: 'Expansion and establishment of blockchain-based TK PAY payment system',
		contents01_2022_ul_02: "Thailand's first public broadcaster to hold a Korean product sales king selection contest",
		contents01_2022_ul_03: 'Thai public broadcaster Ch5 “KING OF SALES” (3 months broadcast)',
		contents01_2022_ul_04: 'Establishment of WWW.TKWTHAI.COM, a Thai shopping mall exclusively for TK PAY',
		contents01_2023_ul_01: 'Establishment of Korean TK PAY offline affiliated stores',
		contents01_2023_ul_02: 'Myeongdong Korean restaurant “Raon” “Jiwoo” “Eve Skin Art” “Golden Sauna” “KAIST” Optician store',
		contents01_2023_ul_03: '“Jang Geum’s Complete Works” etc.',
		contents01_2023_ul_04: 'TK PAY Cup SBS Amateur Golf Tournament confirmed to be broadcast on SBS Golf Channel',
		contents01_2023_ul_05: 'Establishment of TK Tour homepage www.TKWGOLF.CO',
		contents01_2024_ul_01: 'TKC listed (International Exchange XT.COM)',
		contents01_2024_ul_02: 'SBS CF commercial launch “TK Tour”',
		contents01_2024_ul_03:
			'A first in the travel industry, 30% reward when paying with TK PAY at over 130 golf courses and hotels in Thailand',
		contents01_2024_ul_04: 'Completed construction of Samsung Play Store and Apple App Store upgrade apps'
	},
	roadmap: {
		topTitle: 'What is our future?',
		topDescription:
			'We have prepared a roadmap for you, a\n guide to planning your journey and taking\n the first step toward realizing your vision.',
		contents01_title: 'Roadmap',
		contents01_2_title: 'February : \nTKC Tour',
		contents01_2_ul_01: 'TKC Golf Tour advertisement on SBS Golf',
		contents01_2_ul_02: 'Channel TKC Bangkok Golf Course : 43 golf courses',
		contents01_2_ul_03: 'TKC Pattaya Golf Course : 25 golf courses',
		contents01_2_ul_04: 'TKC Hua Hin Golf Course : 9 golf courses',
		contents01_2_ul_05: 'TKC Chiang Mai Golf Course : 8 golf courses',
		contents01_2_ul_06: 'TKC Phuket Golf Course : 9 golf courses',
		contents01_3_title: 'March',
		contents01_3_ul_01: 'TKC listed (XT.COM exchange)',
		contents01_4_title: 'April',
		contents01_4_ul_01: 'PK PAY merchant expansion',
		contents01_4_ul_02: '10 Korean restaurants in Myeongdong (see app)',
		contents01_4_ul_03: 'Korean plastic surgery and dermatology hospitals',
		contents01_4_ul_04: 'Thai golf courses and hotels, shopping centers,',
		contents01_4_ul_05: 'Korean restaurants',
		contents01_4_ul_06: 'Supermarkets, etc.',
		contents01_4_ul_07: '30% reward for TKC merchant payments',
		contents01_5_title: 'May',
		contents01_5_ul_01:
			'Preparing to broadcast season 2 of KING OF SALES on Thai public television Completed co-production agreement with Korean broadcasting group SBS Hub',
		contents01_6_title: 'June',
		contents01_6_ul_01:
			'Launched TK MLM for Thai distribution network Established a distribution network specializing in Korean products in connection with Thai public broadcasting and TV home shopping broadcasting specialized distribution network for Korean products and started broadcasting Currently operating a call center for member acquisition (Thailand)',
		contents01_7_title: 'July',
		contents01_7_ul_01: 'Broadcasting CH5 on public broadcasting in Thailand Started Total 15 episodes (3 months broadcast) See Season 1',
		contents01_8_title: 'August',
		contents01_8_ul_01: 'Started advertising for TKC SBS Amateur Golf Tournament',
		contents01_9_title: 'October',
		contents01_9_ul_01: 'TKC SBS Amateur Golf Tournament held SBS Golf Channel 80-minute programming confirmed',
		contents01_10_title: 'November',
		contents01_10_ul_01: 'KING OF SALES> broadcast on 5CH in Thailand Preparation for broadcasting in Vietnam'
	},
	store: {
		topTitle: 'Meet TK affiliate stores',
		topDescription: 'Experience a special TKStore experience\nwhere the more you use, the more you are\nrewarded with rewards.',
		contents01_title: 'Store',
		detail_label_01: 'Sectors',
		detail_label_02: 'Store name',
		detail_label_03: 'Country',
		detail_label_04: 'Phone number',
		detail_label_05: 'Address',
		detail_label_06: 'Opening hours',
		detail_label_07: 'Main menu',
		detail_label_08: 'Reward',
		detail_label_09: 'View location'
	},
	tour: {
		topTitle: 'Tour package prepared by\nTK Tour',
		topDescription: 'If you pay with TK PAY, you can receive a\n30% reward back, so you can experience a\ncheaper tour.',
		contents01_title: 'Tour'
	},
	tkMedia: {
		topTitle: 'Check out TK media’s\nbroadcast',
		topDescription: 'Experience various contents such as\nshopping broadcasts and news delivered by\nTKMedia.',
		contents01_title: 'TK Media'
	},
	onlineMall: {
		topTitle: 'Meet TK affiliate stores',
		topDescription: 'Experience a special TKStore experience\nwhere the more you use, the more you are\nrewarded with rewards.',
		contents01_title: 'Online Mall',
		contents01_box_btn: 'Learn More'
	},
	tkStory: {
		topTitle: 'Take a look at TK Story’s\nstories so far',
		topDescription: 'We will deliver the story that TK is working\n on. We will create more diverse stories',
		contents01_title: 'TK Story'
	},
	event: {
		topTitle: 'TKworld\nEvent Festival',
		topDescription: "Let's check out TKW special event"
	},
	blog: {
		topTitle: 'We would like to inform you of TK Korea’s future vision and agenda. ',
		topDescription: 'Use TK-pay and feel the value of the excellent reward system'
	},
	manual: {
		topTitle: 'TKworld',
		topDescription: 'Check the Manual\nTK You can enjoy it more simply.'
	},
	error: {}
};
