<template>
	<nav class="sm:py-5 h-[101px] relative" :class="[isSmMenu == true ? 'bg-[#3A3A3A]' : 'bg-[#E6F0FE]']">
		<div
			class="sm:w-[90%] w-[80%] desktop:w-[62.5%] max-w-[1920px] sm:w-full h-full flex justify-around sm:justify-between items-center m-auto"
		>
			<div class="sm:ml-5 flex justify-center items-start cursor-pointer" @click="movePage('/')">
				<div class="w-[100px]"><img src="@/assets/images/sm_nav_logo.svg" /></div>
			</div>
			<div class="flex justify-center items-center sm:hidden">
				<div class="sm:hidden group relative cursor-pointer">
					<div class="py-6 flex px-6 group-hover:text-[#0B6BF3]">
						<div class="flex justify-center items-center">
							<div class="sm:text-sm text-[#010652]">{{ $t('word.navCompany') }}</div>
							<div class="ml-2"><img src="@/assets/images/nav_arrow.svg" /></div>
						</div>
					</div>
					<div class="invisible absolute z-50 w-96 flex flex-col py-4 px-4 group-hover:visible">
						<div class="py-2 dropdown-menu">
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('/')">
									<div><img src="@/assets/images/nav_icon_home.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubHome').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubHome') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubHome') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999] mx-8"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('profile')">
									<div><img src="@/assets/images/nav_icon_profile.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubProfile').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubProfile') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubProfile') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999] mx-8"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('company')">
									<div><img src="@/assets/images/nav_icon_company.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubCompany').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubCompany') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubCompany') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999] mx-8"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('team')">
									<div><img src="@/assets/images/nav_icon_team.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubTeam').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubTeam') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubTeam') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999] mx-8"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('history')">
									<div><img src="@/assets/images/nav_icon_history.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubHistory').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubHistory') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubHistory') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999] mx-8"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('roadmap')">
									<div><img src="@/assets/images/nav_icon_roadmap.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubRoadmap').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubRoadmap') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubRoadmap') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="sm:hidden group relative cursor-pointer w-[150px]">
					<div class="py-6 flex px-6 group-hover:text-[#0B6BF3]">
						<div class="flex justify-center items-center">
							<div class="sm:text-sm text-[#010652]">{{ $t('word.navTKWorld') }}</div>
							<div class="ml-2"><img src="@/assets/images/nav_arrow.svg" /></div>
						</div>
					</div>
					<div class="invisible absolute z-50 w-96 flex flex-col py-4 px-4 group-hover:visible">
						<div class="py-2 dropdown-menu">
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('store')">
									<div><img src="@/assets/images/nav_icon_store.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubStore').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubStore') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubStore') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999] mx-8"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('tkMedia')">
									<div><img src="@/assets/images/nav_icon_tkMedia.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubTKMedia').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubTKMedia') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubTKMedia') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999] mx-8"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('onlineMall')">
									<div><img src="@/assets/images/nav_icon_onlineMall.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="
												$route.name.toLowerCase() == $t('word.navSubOnlineMall').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''
											"
										>
											{{ $t('word.navSubOnlineMall') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubOnlineMall') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999] mx-8"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('tour')">
									<div><img src="@/assets/images/nav_icon_tour.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubTour').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubTour') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubTour') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999] mx-8"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start h-[79px]">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('tkStory')">
									<div><img src="@/assets/images/nav_icon_tkStory.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubTKStory').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubTKStory') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubTKStory') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="sm:hidden group relative cursor-pointer">
					<div class="py-6 flex px-6 group-hover:text-[#0B6BF3]">
						<div class="flex justify-center items-center">
							<div class="sm:text-sm text-[#010652]">{{ $t('word.navCommunity') }}</div>
							<div class="ml-2"><img src="@/assets/images/nav_arrow.svg" /></div>
						</div>
					</div>
					<div class="invisible absolute z-50 w-96 flex flex-col py-4 px-4 group-hover:visible">
						<div class="py-2 dropdown-menu">
							<div class="px-3 py-1 flex justify-start items-start">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('blog')">
									<div><img src="@/assets/images/nav_icon_blog.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubBlog').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubBlog') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubBlog') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999]"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('event')">
									<div><img src="@/assets/images/nav_icon_event.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubEvent').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubEvent') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubEvent') }}</div>
									</div>
								</div>
							</div>
							<div class="h-1"></div>
							<div class="h-px bg-[#999999]"></div>
							<div class="h-1"></div>
							<div class="px-3 py-1 flex justify-start items-start">
								<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('manual')">
									<div><img src="@/assets/images/nav_icon_event.svg" /></div>
									<div class="mt-1 ml-3 flex flex-col justify-start items-start">
										<div
											class="block dropdown-color"
											:class="$route.name.toLowerCase() == $t('word.navSubManual').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
										>
											{{ $t('word.navSubManual') }}
										</div>
										<div class="text-sm font-normal">{{ $t('message.navSubManual') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="flex justify-center items-center sm:hidden">
				<div class="ml-10 flex justify-center items-center cursor-pointer">
					<div
						class="flex justify-center items-center text-sm bg-[#0B6BF3] text-[#fff] rounded-[14px] w-[120px] h-[32px] border-[1px] border-[transparent] border-solid"
						id="whitePaper"
						@click="goPaper"
					>
						<span class="text-[14px]">{{ $t('word.btnWhitePaper') }}</span>
					</div>
				</div>
				<div class="ml-10 flex justify-center items-center">
					<div class="relative select-locale !border-[#0B6BF3]" data-te-dropdown-position="dropup">
						<button
							class="px-5 py-2 flex justify-center items-center text-sm bg-white rounded-[14px] w-[107px] h-[32px]"
							type="button"
							id="selectLocale"
							data-te-dropdown-toggle-ref
							aria-expanded="false"
							data-te-ripple-init
							data-te-ripple-color="light"
						>
							<img :src="localeSelectImage" />
							<span class="ml-5 w-2">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#0B6BF3" class="h-5 w-5">
									<path
										fill-rule="evenodd"
										d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
										clip-rule="evenodd"
									/>
								</svg>
							</span>
						</button>
						<ul
							class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
							aria-labelledby="selectLocale"
							data-te-dropdown-menu-ref
						>
							<template v-for="lo in locales" :key="lo.name">
								<li>
									<div
										@click="changeLocale(lo.name)"
										class="flex w-full whitespace-nowrap bg-transparent px-5 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600 cursor-pointer"
										data-te-dropdown-item-ref
									>
										<img :src="require(`@/assets/images/locale_${lo.name}.png`)" />
										<span class="px-3">{{ lo.label }} </span>
									</div>
								</li>
							</template>
						</ul>
					</div>
				</div>
			</div>
			<div class="sm:mr-5 md:hidden lg:hidden ml-12 sm:ml-10 sm:text-sm cursor-pointer text-[#0B6BF3]">
				<div class="flex justify-center items-center">
					<div v-if="isSmMenu == false" @click="showSmMenu"><img src="@/assets/images/sm_menu_nav.svg" /></div>
					<div v-else @click="hideSmMenu"><img src="@/assets/images/sm_btn_close.svg" /></div>
				</div>
			</div>
		</div>
		<div v-if="isSmMenu == true" class="w-full flex flex-col justify-start items-center bg-[#3A3A3A] z-50 relative">
			<div class="h-5"></div>
			<div class="w-full h-px bg-[#7B7B7B] border-[1px]"></div>
			<div class="h-5"></div>
			<!-- 번역 -->
			<div class="relative select-locale !border-[#0B6BF3] mr-[7%] ml-auto" data-te-dropdown-position="dropup">
				<button
					class="px-5 py-2 flex justify-center items-center text-sm bg-white rounded-[14px] w-[107px] h-[32px]"
					type="button"
					id="selectLocale"
					data-te-dropdown-toggle-ref
					aria-expanded="false"
					data-te-ripple-init
					data-te-ripple-color="light"
				>
					<img :src="localeSelectImage" />
					<span class="ml-5 w-2">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#0B6BF3" class="h-5 w-5">
							<path
								fill-rule="evenodd"
								d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
								clip-rule="evenodd"
							/>
						</svg>
					</span>
				</button>
				<ul
					class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
					aria-labelledby="selectLocale"
					data-te-dropdown-menu-ref
				>
					<template v-for="lo in locales" :key="lo.name">
						<li>
							<div
								@click="changeLocale(lo.name)"
								class="flex w-full whitespace-nowrap bg-transparent px-5 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600 cursor-pointer"
								data-te-dropdown-item-ref
							>
								<img :src="require(`@/assets/images/locale_${lo.name}.png`)" />
								<span class="px-3">{{ lo.label }} </span>
							</div>
						</li>
					</template>
				</ul>
			</div>
			<!-- Company -->
			<div class="w-full flex flex-col justify-center items-center z-50">
				<div class="h-8"></div>
				<div @click="toggleCompany" class="px-8 w-full flex justify-between items-center cursor-pointer">
					<div class="text-xl text-[#0B6BF3]">{{ $t('word.navCompany') }}</div>
					<div v-if="isSmMenuCompany == false"><img src="@/assets/images/sm_btn_nav_right.svg" /></div>
					<div v-else><img src="@/assets/images/sm_btn_nav_down.svg" /></div>
				</div>
				<div v-if="isSmMenuCompany == true" class="h-8"></div>
				<div v-if="isSmMenuCompany == true" class="p-5 w-full flex flex-col justify-center items-start bg-white">
					<div class="px-3 py-1 flex justify-start items-start">
						<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('/')">
							<div><img src="@/assets/images/nav_icon_home.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div
									class="block dropdown-color"
									:class="$route.name.toLowerCase() == $t('word.navSubHome').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
								>
									{{ $t('word.navSubHome') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubHome') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start h-[79px]">
						<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('company')">
							<div><img src="@/assets/images/nav_icon_company.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div
									class="block dropdown-color"
									:class="$route.name.toLowerCase() == $t('word.navSubCompany').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
								>
									{{ $t('word.navSubCompany') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubCompany') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start h-[79px]">
						<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('team')">
							<div><img src="@/assets/images/nav_icon_team.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div
									class="block dropdown-color"
									:class="$route.name.toLowerCase() == $t('word.navSubTeam').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
								>
									{{ $t('word.navSubTeam') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubTeam') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start h-[79px]">
						<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('history')">
							<div><img src="@/assets/images/nav_icon_history.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div
									class="block dropdown-color"
									:class="$route.name.toLowerCase() == $t('word.navSubHistory').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
								>
									{{ $t('word.navSubHistory') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubHistory') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start h-[79px]">
						<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('roadmap')">
							<div><img src="@/assets/images/nav_icon_roadmap.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div
									class="block dropdown-color"
									:class="$route.name.toLowerCase() == $t('word.navSubRoadmap').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
								>
									{{ $t('word.navSubRoadmap') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubRoadmap') }}</div>
							</div>
						</div>
					</div>
					<div class="h-8"></div>
				</div>
			</div>
			<!-- TK World -->
			<div class="w-full flex flex-col justify-center items-center">
				<div class="h-8"></div>
				<div @click="toggleTKWorld" class="px-8 w-full flex justify-between items-center cursor-pointer">
					<div class="text-xl text-[#0B6BF3]">{{ $t('word.navTKWorld') }}</div>
					<div v-if="isSmMenuTKWorld == false"><img src="@/assets/images/sm_btn_nav_right.svg" /></div>
					<div v-else><img src="@/assets/images/sm_btn_nav_down.svg" /></div>
				</div>
				<div v-if="isSmMenuTKWorld == true" class="h-8"></div>
				<div v-if="isSmMenuTKWorld == true" class="p-5 w-full flex flex-col justify-center items-start bg-white">
					<div class="px-3 py-1 flex justify-start items-start h-[79px]">
						<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('store')">
							<div><img src="@/assets/images/nav_icon_store.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div
									class="block dropdown-color"
									:class="$route.name.toLowerCase() == $t('word.navSubStore').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
								>
									{{ $t('word.navSubStore') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubStore') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start h-[79px]">
						<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('tkMedia')">
							<div><img src="@/assets/images/nav_icon_tkMedia.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div
									class="block dropdown-color"
									:class="$route.name.toLowerCase() == $t('word.navSubTKMedia').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
								>
									{{ $t('word.navSubTKMedia') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubTKMedia') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start h-[79px]">
						<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('onlineMall')">
							<div><img src="@/assets/images/nav_icon_onlineMall.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div
									class="block dropdown-color"
									:class="$route.name.toLowerCase() == $t('word.navSubOnlineMall').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
								>
									{{ $t('word.navSubOnlineMall') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubOnlineMall') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start h-[79px]">
						<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('tour')">
							<div><img src="@/assets/images/nav_icon_tour.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div
									class="block dropdown-color"
									:class="$route.name.toLowerCase() == $t('word.navSubTour').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
								>
									{{ $t('word.navSubTour') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubTour') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start h-[79px]">
						<div class="px-6 py-2 w-full flex dropdown-over" @click="movePage('tkStory')">
							<div><img src="@/assets/images/nav_icon_tkStory.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div
									class="block dropdown-color"
									:class="$route.name.toLowerCase() == $t('word.navSubTKStory').replace(/\s+/g, '').toLowerCase() ? 'nav-active' : ''"
								>
									{{ $t('word.navSubTKStory') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubTKStory') }}</div>
							</div>
						</div>
					</div>
					<div class="h-8"></div>
				</div>
			</div>
			<!-- 커뮤니티 -->
			<div class="w-full flex flex-col justify-center items-center">
				<div class="h-8"></div>
				<div @click="toggleCommunity" class="px-8 w-full flex justify-between items-center cursor-pointer">
					<div class="text-xl text-[#0B6BF3]">{{ $t('word.navCommunity') }}</div>
					<div v-if="isSmMenuCommunity == false"><img src="@/assets/images/sm_btn_nav_right.svg" /></div>
					<div v-else><img src="@/assets/images/sm_btn_nav_down.svg" /></div>
				</div>
				<div v-if="isSmMenuCommunity == true" class="h-8"></div>
				<div v-if="isSmMenuCommunity == true" class="p-5 w-full flex flex-col justify-center items-start bg-white">
					<div class="px-3 py-1 flex justify-start items-start">
						<div class="px-6 py-2 w-full flex dropdown-over cursor-pointer" @click="movePage('blog')">
							<div><img src="@/assets/images/nav_icon_wallet.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div class="block dropdown-color" :class="$route.name == $t('word.navSubBlog').toLowerCase() ? 'nav-active' : ''">
									{{ $t('word.navSubBlog') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubBlog') }}</div>
							</div>
						</div>
					</div>
					<div class="h-1"></div>
					<div class="w-full h-px bg-[#999999]"></div>
					<div class="h-1"></div>
					<div class="px-3 py-1 flex justify-start items-start">
						<div class="px-6 py-2 w-full flex dropdown-over cursor-pointer" @click="movePage('event')">
							<div><img src="@/assets/images/nav_icon_wexex.svg" /></div>
							<div class="mt-1 ml-3 flex flex-col justify-start items-start">
								<div class="block dropdown-color" :class="$route.name == $t('word.navSubEvent').toLowerCase() ? 'nav-active' : ''">
									{{ $t('word.navSubEvent') }}
								</div>
								<div class="text-sm font-normal">{{ $t('message.navSubEvent') }}</div>
							</div>
						</div>
					</div>
					<div class="h-8"></div>
				</div>
			</div>
			<div class="flex justify-center items-center cursor-pointer my-[60px]">
				<div
					class="flex justify-center items-center text-sm bg-[#0B6BF3] text-[#fff] rounded-[14px] w-[120px] h-[32px] border-[1px] border-[transparent] border-solid"
					id="whitePaper"
					@click="goPaper"
				>
					<span class="text-[14px]">{{ $t('word.btnWhitePaper') }}</span>
				</div>
			</div>
		</div>
	</nav>
	<router-view v-if="isMobile == false" />
	<router-view v-else name="mobile" />
	<footer class="main-footer">
		<div class="footer-bg">
			<div class="h-10"></div>
			<div
				class="grid grid-cols-5 w-[70%] m-auto sm:grid-cols-[100%] md:grid-cols-[200px_200px_200px_200px_200px] gap-y-8 place-content-center items-start text-white sm:w-[90%] sm:mt-[57px] sm:mx-[auto]"
			>
				<div class="flex flex-col justify-center items-start sm:flex-row sm:flex-wrap sm:justify-start">
					<div class="text-2xl sm:w-[100%] sm:mb-[20px]">{{ $t('word.siteMapSns') }}</div>
					<div class="h-5"></div>
					<div class="flex flex-row justify-center">
						<div class="cursor-pointer">
							<a href="https://www.facebook.com/Meta/?locale=ko_KR" target="_blank">
								<div class="mr-2"><img src="@/assets/images/site_icon_meta.svg" /></div>
							</a>
						</div>
						<div class="cursor-pointer">
							<a href="https://www.facebook.com/Meta/?locale=ko_KR" target="_blank">
								<div class="ml-2"><img src="@/assets/images/site_icon_telegram.svg" /></div>
							</a>
						</div>
					</div>
					<div class="h-5"></div>
					<div class="flex flex-row justify-center sm:ml-4">
						<div class="cursor-pointer">
							<a href="https://www.facebook.com/Meta/?locale=ko_KR" target="_blank">
								<div class="mr-2"><img src="@/assets/images/site_icon_youture.svg" /></div>
							</a>
						</div>
						<div class="cursor-pointer">
							<a href="https://www.facebook.com/Meta/?locale=ko_KR" target="_blank">
								<div class="ml-2"><img src="@/assets/images/site_icon_x.svg" /></div>
							</a>
						</div>
					</div>
					<div class="h-5"></div>
					<div class="flex flex-row justify-center sm:ml-4">
						<div class="cursor-pointer">
							<a href="https://www.facebook.com/Meta/?locale=ko_KR" target="_blank">
								<div class="mr-2"><img src="@/assets/images/site_icon_instagram.svg" /></div>
							</a>
						</div>
						<div class="cursor-pointer">
							<a href="https://www.facebook.com/Meta/?locale=ko_KR" target="_blank">
								<div class="ml-2"><img src="@/assets/images/site_icon_tiktok.svg" /></div>
							</a>
						</div>
					</div>
					<div class="h-5"></div>
					<div class="flex flex-row justify-center sm:ml-4">
						<div class="cursor-pointer">
							<a href="https://www.facebook.com/Meta/?locale=ko_KR" target="_blank">
								<div class="mr-2"><img src="@/assets/images/site_icon_kakao.svg" /></div>
							</a>
						</div>
					</div>
				</div>
				<div class="flex flex-col justify-center items-start sm:mt-[30px] sm:flex-row sm:flex-wrap sm:justify-start">
					<div class="text-2xl sm:w-[100%]">{{ $t('word.siteMapExplore') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer sm:w-[50%] sm:mt-[20px]" @click="movePage('/')">
						{{ $t('word.siteMapHome') }}
					</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer sm:w-[50%] sm:mt-[20px]" @click="movePage('profile')">
						{{ $t('word.siteMapProfile') }}
					</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer sm:w-[50%] sm:mt-[20px]" @click="movePage('company')">
						{{ $t('word.siteMapCompany') }}
					</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer sm:w-[50%] sm:mt-[20px]" @click="movePage('team')" goTeam>
						{{ $t('word.siteMapTeam') }}
					</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer sm:w-[50%] sm:mt-[20px]" @click="movePage('history')">
						{{ $t('word.siteMapHistory') }}
					</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer sm:w-[50%] sm:mt-[20px]" @click="movePage('roadMap')">
						{{ $t('word.siteMapRoadMap') }}
					</div>
				</div>
				<div class="flex flex-col justify-center items-start sm:mt-[30px] sm:flex-row sm:flex-wrap sm:justify-start">
					<div class="text-2xl sm:w-[100%]">{{ $t('word.siteMapParticipate') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer sm:w-[50%] sm:mt-[20px]" @click="movePage('blog')">
						{{ $t('word.siteMapBlog') }}
					</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer sm:w-[50%] sm:mt-[20px]" @click="movePage('event')">
						{{ $t('word.siteMapEvent') }}
					</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer sm:w-[50%] sm:mt-[20px]" @click="movePage('manual')">
						{{ $t('word.siteMapManual') }}
					</div>
				</div>
				<div class="flex flex-col justify-center items-start sm:mt-[30px] sm:flex-row sm:flex-wrap sm:justify-start">
					<div class="text-2xl sm:w-[100%]">{{ $t('word.siteMapAbout') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer sm:w-[100%] sm:mt-[20px]" @click="movePage('privacyPolicy')">
						{{ $t('word.siteMapPrivacy') }}
					</div>
					<div class="h-5"></div>
					<div class="flex flex-row justify-center sm:w-[50%] sm:justify-start sm:ml-0 sm:mt-5">
						<div class="cursor-pointer">
							<a href="https://play.google.com/store/apps/details?id=io.tkwthai.tkpay" target="_blank">
								<div class="mr-2"><img src="@/assets/images/site_icon_googleDown.svg" /></div>
							</a>
						</div>
					</div>
					<div class="h-5"></div>
					<div class="flex flex-row justify-center sm:w-[50%] sm:justify-start sm:ml-0 sm:mt-5">
						<div class="cursor-pointer">
							<a href="https://apps.apple.com/kr/app/tkcpay/id6443688587" target="_blank">
								<div class="mr-2"><img src="@/assets/images/site_icon_IosDown.svg" /></div>
							</a>
						</div>
					</div>
				</div>
				<div class="flex flex-col justify-center items-start sm:mt-[30px]">
					<div class="text-2xl">{{ $t('word.siteMapContact') }}</div>
					<div class="h-5"></div>
					<div class="text-sm hover:text-[#ADADAD] cursor-pointer">
						<a href="mailto:tkcpay0@gmail.com">{{ $t('word.siteMapEmail') }}</a>
					</div>
					<div class="h-5"></div>
					<div class="text-sm">
						<span>{{ $t('word.siteMapTel') }}</span>
					</div>
					<div class="h-5"></div>
					<div class="text-sm whitespace-pre-line sm:whitespace-normal">
						<span>{{ $t('word.siteMapAddress') }}</span>
					</div>
				</div>
			</div>
			<div class="h-6"></div>
			<div class="h-px bg-[#454F49]"></div>
			<div class="h-8"></div>
			<div
				class="w-full grid grid-cols-4 sm:grid-cols-1 gap-x-10 gap-y-8 place-content-center items-center text-xl text-white sm:text-[14px] sm:h-[60px]"
			>
				<div class="sm:hidden"></div>
				<div class="col-span-2 text-center"><span class="font-[Inter] font-light">©</span> TKworld 2024 All rights reserved.</div>
			</div>
			<div class="h-8"></div>
		</div>
	</footer>
	<!-- <LoginModal :visible="isShowLogin" @hide="hideLoginModal" @googleLogin="loginSdk"> </LoginModal> -->
	<!-- <ProfileModal :visible="isShowProfile" @hide="hideProfileModal" @googleLogout="logout"> </ProfileModal> -->
	<LoadingSpinner v-if="store.state.isLoading"></LoadingSpinner>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import store from '@/store';
import router from '@/router';
// import axios from 'axios';
// import http from '@/api/http';
// import { googleTokenLogin, googleLogout, GoogleLogin } from 'vue3-google-login';
// import LoginModal from '@/components/LoginModal.vue';
// import ProfileModal from '@/components/ProfileModal.vue';
// import md5 from 'crypto-js/md5';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { Dropdown, initTE } from 'tw-elements';
import { useI18n } from 'vue-i18n';
import { locales } from '@/config/constants/locale';

const { t, locale } = useI18n();

const isMobile = ref(false);
const isSmMenu = ref(false);
const isSmMenuCompany = ref(false);
const isSmMenuTKWorld = ref(false);
const isSmMenuCommunity = ref(false);

const localeSelectImage = ref('');

onMounted(() => {
	initTE({ Dropdown });

	onResize();
	window.addEventListener('resize', onResize, { passive: true });

	isSmMenu.value = false;

	changeLocale(store.state.locale);
});

onUnmounted(() => {
	if (typeof window === 'undefined') return;
	window.removeEventListener('resize', onResize, { passive: true });
});

const onResize = () => {
	isSmMenu.value = false;

	/* 	if (window.innerWidth < 650 || window.screen.width < 650) {
		isMobile.value = true;
	} else {
		isMobile.value = false;
	} */
	// console.log(window.screen.width + ' => ' + isMobile.value);
};

const showSmMenu = () => {
	isSmMenu.value = true;
	toggleAllClose();
};

const hideSmMenu = () => {
	isSmMenu.value = false;
};
const scrollTop = () => {
	window.scrollTo(0, 0);
};
const toggleAllClose = () => {
	isSmMenuCompany.value = false;
	isSmMenuTKWorld.value = false;
	isSmMenuCommunity.value = false;
};

const toggleCompany = () => {
	if (isSmMenuCompany.value == true) {
		isSmMenuCompany.value = false;
	} else {
		isSmMenuCompany.value = true;
		isSmMenuTKWorld.value = false;
		isSmMenuCommunity.value = false;
	}
	scrollTop();
};
const toggleTKWorld = () => {
	if (isSmMenuTKWorld.value == true) {
		isSmMenuTKWorld.value = false;
	} else {
		isSmMenuTKWorld.value = true;
		isSmMenuCompany.value = false;
		isSmMenuCommunity.value = false;
	}
	scrollTop();
};
const toggleCommunity = () => {
	if (isSmMenuCommunity.value == true) {
		isSmMenuCommunity.value = false;
	} else {
		isSmMenuCommunity.value = true;
		isSmMenuCompany.value = false;
		isSmMenuTKWorld.value = false;
	}
	scrollTop();
};
const changeLocale = lang => {
	if (localeSelectImage.value != lang) {
		localeSelectImage.value = require('@/assets/images/locale_' + lang + '.png');

		if (lang != '' && store.state.locale != lang) {
			store.commit('setLocale', { lang });
			locale.value = lang;
		}
	}
};

const goPaper = () => {
	window.open('/pdf/TK profile-Eng.pdf', '_blank');
};

const movePage = link => {
	isSmMenu.value = false;
	scrollTop();
	router.push(link);
};

onMounted(() => {});
</script>

<style scoped>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#vanta_area {
	/* width: 100vw; */
	/* height: 100vh; */
	/* position: fixed; */
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #1adf52;
}
.nav-active {
	color: #0b6bf3;
}

.dropdown-over:hover .dropdown-color {
	color: #0b6bf3;
}

.contact-btn {
	border-radius: 20px;
	background: #000;
	color: #1adf52;
}

.dropdown-menu {
	border-radius: 13px;
	background: #fff;
}

.main-footer {
	position: relative;
	margin-top: 0px;
	font-family: 'Outfit';
}

.footer-bg {
	background: #292929;
}

.select-locale {
	border-radius: 14px;
	border: 1px solid #454f49;
	color: #454f49;
}

.sm-menu-bg-color {
	background: #3a3a3a;
}

.sm-login-btn {
	border-radius: 20px;
	background: #000;
	color: #1adf52;
}
</style>
