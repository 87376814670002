import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'home',
		components: {
			default: () => import('@/views/pc/HomeView.vue'),
			mobile: () => import('@/views/mobile/HomeView.vue')
		}
	},
	{
		path: '/profile',
		name: 'profile',
		components: {
			default: () => import('@/views/pc/ProfileView.vue'),
			mobile: () => import('@/views/mobile/ProfileView.vue')
		}
	},
	{
		path: '/company',
		name: 'company',
		components: {
			default: () => import('@/views/pc/CompanyView.vue'),
			mobile: () => import('@/views/mobile/CompanyView.vue')
		}
	},
	{
		path: '/team',
		name: 'team',
		components: {
			default: () => import('@/views/pc/TeamView.vue'),
			mobile: () => import('@/views/mobile/TeamView.vue')
		}
	},
	{
		path: '/history',
		name: 'history',
		components: {
			default: () => import('@/views/pc/HistoryView.vue'),
			mobile: () => import('@/views/mobile/HistoryView.vue')
		}
	},
	{
		path: '/roadmap',
		name: 'roadmap',
		components: {
			default: () => import('@/views/pc/RoadMapView.vue'),
			mobile: () => import('@/views/mobile/RoadMapView.vue')
		}
	},
	{
		path: '/store',
		name: 'store',
		components: {
			default: () => import('@/views/pc/StoreView.vue'),
			mobile: () => import('@/views/mobile/StoreView.vue')
		}
	},
	{
		path: '/tkMedia',
		name: 'tkMedia',
		components: {
			default: () => import('@/views/pc/TKMediaView.vue'),
			mobile: () => import('@/views/mobile/TKMediaView.vue')
		}
	},
	{
		path: '/tour',
		name: 'tour',
		components: {
			default: () => import('@/views/pc/TourView.vue'),
			mobile: () => import('@/views/mobile/TourView.vue')
		}
	},
	{
		path: '/onlineMall',
		name: 'onlineMall',
		components: {
			default: () => import('@/views/pc/OnlineMallView.vue'),
			mobile: () => import('@/views/mobile/OnlineMallView.vue')
		}
	},
	{
		path: '/tkStory',
		name: 'tkStory',
		components: {
			default: () => import('@/views/pc/TKStoryView.vue'),
			mobile: () => import('@/views/mobile/TKStoryView.vue')
		}
	},
	{
		path: '/blog',
		name: 'blog',
		components: {
			default: () => import('@/views/pc/BlogView.vue'),
			mobile: () => import('@/views/mobile/BlogView.vue')
		}
	},
	{
		path: '/event',
		name: 'event',
		components: {
			default: () => import('@/views/pc/EventView.vue'),
			mobile: () => import('@/views/mobile/EventView.vue')
		}
	},
	{
		path: '/manual',
		name: 'manual',
		components: {
			default: () => import('@/views/pc/ManualView.vue'),
			mobile: () => import('@/views/mobile/ManualView.vue')
		}
	},
	{
		path: '/detail',
		name: 'detail',
		components: {
			default: () => import('@/views/pc/DetailView.vue'),
			mobile: () => import('@/views/mobile/DetailView.vue')
		}
	},
	{
		path: '/privacyPolicy',
		name: 'privacyPolicy',
		components: {
			default: () => import('@/views/pc/PrivacyPolicyView.vue'),
			mobile: () => import('@/views/mobile/PrivacyPolicyView.vue')
		}
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
